import { LinkOutlined } from "@ant-design/icons";
import { Editor } from "@tiptap/react";
import { useEffect, useState } from "react";
import LinkPopup from "./Popup";

type LinkMenuProps = {
	editor: Editor;
};

export default function LinkMenu({ editor }: LinkMenuProps) {
	const [isModalOpen, setModalState] = useState(false),
		attr = editor.getAttributes("link");
	useEffect(() => {
		setModalState(false);
	}, [editor.view.state.selection.empty]);
	return (
		<button
			key={"link"}
			className={"p-1 z-20"}
			disabled={editor.view.state.selection.empty}
			type="button"
		>
			<span
				onClick={() => setModalState(!isModalOpen)}
				className={`${isModalOpen ? "bg-slate-700" : ""}  rounded-sm not:disabled:hover:bg-slate-600 disabled:cursor-not-allowed p-1`}
			>
				<LinkOutlined
					style={{
						color: editor.view.state.selection.empty ? "grey" : "white",
					}}
				/>
			</span>
			<div className="absolute mt-3">
				{isModalOpen && (
					<LinkPopup
						url={attr.href}
						isTargetNewTab={attr.target}
						editor={editor}
					/>
				)}
			</div>
		</button>
	);
}
