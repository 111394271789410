import { YoutubeOutlined } from "@ant-design/icons";
import { Editor } from "@tiptap/react";
import { useEffect, useState } from "react";
import YoutubePopup from "./Popup";

type YoutubeMenuProps = {
	editor: Editor;
};

export default function YoutubeMenu({ editor }: YoutubeMenuProps) {
	const [isModalOpen, setModalState] = useState(false);
	useEffect(() => {
		setModalState(false);
	}, [editor.view.state.selection.empty]);
	return (
		<button key={"link"} className={"p-1 z-20"} type="button">
			<span
				onClick={() => setModalState(!isModalOpen)}
				className={`${isModalOpen ? "bg-slate-700" : ""} rounded-sm hover:bg-slate-600 p-1`}
			>
				<YoutubeOutlined
					style={{
						color: "white",
						height: 3,
					}}
				/>
			</span>
			<div className="absolute mt-3">
				{isModalOpen && (
					<YoutubePopup
						closePopup={() => setModalState(false)}
						editor={editor}
					/>
				)}
			</div>
		</button>
	);
}
