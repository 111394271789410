import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import React, { useEffect, useState } from "react";

const SelectWithApiData: React.FC<{
	value?: any;
	onChange?:
		| ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
		| undefined;
	apiAddCall: any;
	apiFetchCall: any;
	dataMappingFunction?: any;
	labelField?: string;
	valueField?: string;
	multiValue?: boolean;
}> = ({
	value,
	onChange,
	multiValue,
	apiFetchCall,
	labelField = "name",
	valueField = "_id",
	dataMappingFunction = (data: any[]) => {
		return data.map((i: any) => ({
			label: i[labelField],
			value: i[valueField],
			key: i._id,
		}));
	},
	apiAddCall,
}) => {
	const [state, setState] = useState({ data: [], loading: false });
	const [creating, setIsCreating] = useState(false);
	const [inputValue, setInputValue] = useState("");

	const fetchData = () => {
		if (apiFetchCall) {
			setState({ ...state, loading: true });
			apiFetchCall().then((res: any) => {
				setState({ data: res.data, loading: false });
			});
		}
	};
	const onAddEntry = () => {
		if (inputValue.trim() !== "" && !creating) {
			setIsCreating(true);
			apiAddCall({ [labelField]: inputValue })
				.then(() => {
					setInputValue("");
					setIsCreating(false);
					fetchData();
				})
				.catch(() => {
					setInputValue("");
					setIsCreating(false);
				});
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Select
			allowClear
			value={value}
			onChange={onChange}
			showSearch
			optionFilterProp="label"
			mode={multiValue ? "multiple" : undefined}
			dropdownRender={(menu) =>
				apiAddCall ? (
					<div>
						{menu}
						<Divider style={{ margin: "4px 0" }} />
						<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
							<Input
								disabled={creating}
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								size="small"
								style={{ flex: "auto" }}
							/>
							<div
								onClick={onAddEntry}
								style={{
									flex: "none",
									padding: "8px",
									display: "block",
									cursor: "pointer",
								}}
							>
								<PlusOutlined /> Add Entry
							</div>
						</div>
					</div>
				) : (
					menu
				)
			}
			loading={state.loading}
			style={{ width: "100%" }}
			options={dataMappingFunction(state.data || [])}
		/>
	);
};

export default SelectWithApiData;
